import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { axiosWithToken, functionBaseUrl } from "../../common/firebase";
import { listOfSubscriptions } from "../../common/envConfig";
import CohortGraph from "react-cohort-graph";

function CohortAnalysisComponent({
  subscriptions,
}) {
  const [productType, setProductType] = useState(listOfSubscriptions.PREMIUM);
  const [subType, setSubType] = useState("all");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const handleProductType = (e) => {
    setProductType(e.target.value);
  };

  const handleSubType = (e) => {
    setSubType(e.target.value);
  };

  useEffect(() => {
    if (subscriptions?.length > 0) {
      loadSubData();
    }
  }, [
    productType,
    subType,
    subscriptions,
  ]);

  const loadSubData = async() => {
    setLoading(true);
    setData({});

    await axiosWithToken(`${functionBaseUrl}/api/cohortHelper`, {
    //await axiosWithToken("http://localhost:5000/sportsbox-3dgolf/us-central1/api/cohortHelper", {
      method: "POST",
      data: {
        productType,
        subscriptions,
        subType,
      },
    }).then((res) => {
      const cleanRetentionRates = (rates) =>
        rates.map((rate) => parseFloat(rate.replace("%", "")));
      
      const result = {
        months: Object.fromEntries(
          res.data.map(({ cohort, retentionRates, newAccountsSold }) => {
            const cohortLabel = `${cohort} (${newAccountsSold})`;
            return [cohortLabel, cleanRetentionRates(retentionRates)];
          })
        )
      };
      
      setData(result); 
      setLoading(false);
    })
  };

  return (
    <>
      <Container component="main" style={{ padding: "20px 0" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Product Type</InputLabel>
              <Select
                label="Product Type"
                onChange={handleProductType}
                value={productType}
              >
                <MenuItem value={listOfSubscriptions.PREMIUM}>3D Pro</MenuItem>
                <MenuItem value={listOfSubscriptions.STUDENT_LITE}>3D Player</MenuItem>
                <MenuItem value={listOfSubscriptions.STUDIO_MASTER}>3D Studio</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Subscription Type</InputLabel>
              <Select
                label="Subscription Type"
                onChange={handleSubType}
                value={subType}
              >
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"annually"}>Annually</MenuItem>
                <MenuItem value={"all"}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {Object.keys(data).length > 0 &&
              <CohortGraph
                data={data}
                dataType="months"
                defaultValueType={["percent"]}
                keyCellColor="#fff"
                headerCellColor="#ccc"
                bodyCellColor="#eee"
                headerCellStyles={{
                  color: "black",
                }}
                bodyCellStyles={{
                  color: "black",
                }}
                tableCellStyles={{
                  color: "black",
                }}
              />
            }
          </Grid>
          <Grid item xs={12}>
            {!loading ? (
              <></>
            ) : (
              <Grid
                style={{
                  margin: "auto",
                  padding: 100,
                  display: loading ? "block" : "none",
                }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export const CohortAnalysis = CohortAnalysisComponent;
