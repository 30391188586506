import React, { Component } from "react";
import PropTypes from "prop-types";
import firebase from "../../common/firebase";
import { withStyles } from "@material-ui/styles";
import {
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  CssBaseline,
  Container,
  Avatar,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import palette from "../../theme/palette";
import { AccountNotFound } from "../account/components";
import { SummaryCharts } from "../summary/SummaryCharts";
import { ENVIRONMENT } from "../../common/envConfig";
import moment from "moment";
import { CohortAnalysis } from "../summary/CohortAnalysis";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

const theme = createMuiTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
  },
  palette: {
    secondary: {
      main: palette.primary.main,
    },
  },
});

class SummaryGraphs extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props);
    this.state = {
      uid: firebaseUser.uid,
      error: "",
      loading: true,
      deviceLimitExceptionList: [],
      subscriptions: [],
      cohortSubs: [],
    };
  }

  //Tests if an email is associated with a development account using wildcards
  //and a list of development accounts that are not caught by the wildcards
  isTestAccount = (email, deviceLimitExceptionList) => {
    if (!deviceLimitExceptionList)
      deviceLimitExceptionList = this.state.deviceLimitExceptionList;
    // const emailExceptionList = ["accuracy.perf@gmail.com","accuracy.faceon@gmail.com","accuracy.dtl@gmail.com","accuracy.collect1@gmail.com","accuracy.collect2@gmail.com","terryrowles@gmail.com","Sportsbox3dstaff@gmail.com","Jclay@pnwgolfcenters.com","Dan@fiveirongolf.com","wancoachtest1@gmail.com","ryan.crawley@pga.com","troondemo@gmail.com","ryan.crawley@pga.com","nikitadniestrov@tidway.com.ua","nashton0610@gmail.com","richk@aithinktank.com","mmoslenko@lagolfshafts.com","6connoro@gmail.com","noppwonglaw@gmail.com","timashton@frontiernet.net","databases.sb@gmail.com","mizuho.lpga@gmail.com","demo1.lpga@gmail.com","lululemon.3dgolf@gmail.com"]
    const must_not_terms = [/sb3d/, /3dsb/, /sportsbox/, /demo/, /_sb3d/];
    return (
      deviceLimitExceptionList?.includes(email) ||
      must_not_terms.some((term) => term.test(email))
    );
  };

  async componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to view summary reports" });
      this.setState({ loading: false });
    } else {
      const user = await firebase
        .firestore()
        .collection("users")
        .doc(this.state.uid)
        .get();
      if (!user) {
        console.log("Cannot find the user document");
        this.setState({
          loading: false,
          error: "There was an error retrieving your info",
        });
      }
      this.setState({ role: user?.data()?.role });

      if (!["admin"].includes(user?.data()?.role)) {
        this.setState({
          loading: false,
          error: "You are not authorized to access this page.",
        });
      }

      //Retrieve list of emails associated with development accounts
      let deviceLimitExceptionList = [];
      if (ENVIRONMENT === "production") {
        const deviceSnap = await firebase
          .firestore()
          .collection("mobileAppSettings")
          .doc("X3X42cbYDIT9t9BL43bz")
          .get();

        deviceLimitExceptionList = deviceSnap?.data()?.deviceLimitExceptionList;
        this.setState({ deviceLimitExceptionList });
      }

      const tempSubsSnap = await firebase
        .firestore()
        .collection("subscriptions")
        .where("startDate", ">", moment().subtract(14, "days").toDate())
        .get();
      
      let tempSubs = [];
      tempSubsSnap.forEach((doc) => {
        let data = doc.data();
        if (!this.isTestAccount(data?.userEmail, this.state.deviceLimitExceptionList)) {
          tempSubs.push({ ...data, id: doc.id });
        }
      });
      this.setState({
        subscriptions: tempSubs,
        loading: false,
      });

      const subsSnap = await firebase
        .firestore()
        .collection("subscriptions")
        .get();

      let allSubs = [];
      subsSnap.forEach((doc) => {
        let data = doc.data();
        if (!this.isTestAccount(data?.userEmail, this.state.deviceLimitExceptionList)) {
          allSubs.push({ ...data, id: doc.id });
        }
      });

      const cohortSubs = allSubs
        .filter((sub) => Number(sub.startDate) >= 1672560000000)
        .map((data) => {
          return {
            //id: data.id,
            startDate: data.startDate,
            endDate: data.endDate,
            productId: data.productId,
            transactionId: data.transactionId,
            subLabel: data.subLabel,
          };
        });

      this.setState({
        subscriptions: allSubs,
        cohortSubs,
      });
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        {this.state.role === "admin" ? (
          <Container className={classes.paper} component="main">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <ShowChartIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h4"
                style={{ marginBottom: "10px" }}
              >
                SUMMARY GRAPHS
              </Typography>
            </div>
            <div style={{ width: "100%" }}>
              <MuiThemeProvider theme={theme}>
                {this.state.subscriptions && <SummaryCharts 
                  deviceLimitExceptionList={this.state.deviceLimitExceptionList}
                  subscriptions={this.state.subscriptions}
                />}
                {this.state.cohortSubs && <CohortAnalysis subscriptions={this.state.cohortSubs} />}
              </MuiThemeProvider>
            </div>
          </Container>
        ) : (
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            style={{
              display:
                this.state.error && !this.state.loading ? "block" : "none",
            }}
          >
            <AccountNotFound error={{ error: this.state.error }} />
          </Grid>
        )}
      </div>
    );
  }
}

SummaryGraphs.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(SummaryGraphs);
