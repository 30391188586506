import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Divider,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { Swings } from "./Swings";
import Swal from "sweetalert2";
import JSZip, { filter } from "jszip";
import axios from "axios";
import { SelectV3 } from "../../components/redesign-components/SelectV3/SelectV3";
import { SelectV3WithDatePicker } from "../../components/redesign-components/SelectV3/SelectV3WithDatePicker";
import * as moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { IndicatorSelectList } from "./components/IndicatorSelectList/IndicatorSelectList";
import { HorizontalScrollWrapper } from "./components/HorizontalScrollWrapper/HorizontalScrollWrapper";
import { PictureInPictureVideo } from "./components/PictureInPictureVideo/PictureInPictureVideo";
import { SelectV3WithServerSearch } from "../../components/redesign-components/SelectV3/SelectV3WithServerSearch";
import { SwingTable2 } from "./components/Table/SwingTable2";
import Checkbox from "@material-ui/core/Checkbox";

import { TableRowTag } from "./components/TableRowTag/TableRowTag";
import { ClubSelect } from "./components/Selects/ClubSelect";
import { TagsSelect } from "./components/Selects/TagsSelect";
import { IOSSwitch } from "./components/IOSSwitch/IOSSwitch";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    paddingTop: 20,
  },
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    minHeight: "fit-content",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  vertDivider: {
    margin: theme.spacing(2, 0),
    //padding: theme.spacing(0, 2),
    //marginLeft: 30,
    //marginRight: 30,
  },
  heading: {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 15,
  },
  card: {
    width: "100%",
    //minWidth: '30%',
    //maxWidth: '300px',
    height: "100%",
    borderRadius: "12px",
    border: "1px solid #EAECF0",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  cardText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: "Manrope",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "38px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  displayMd: {
    fontFamily: "Manrope",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    marginRight: 10,
  },
  smallCard: {
    width: "200px",
    //maxWidth: '200px',
    height: "120px",
    borderRadius: "12px",
    border: "1px solid #EAECF0",
    padding: theme.spacing(2.5),
    marginRight: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "48%",
      marginRight: "2%",
      marginBottom: "2%",
    },
  },
  button: {
    width: "fit-content",
    height: "fit-content",
    padding: "10px 16px",
    borderRadius: "64px",
    //border: '1px solid #E9DDFD',
    gap: "8px",
    background: "#F2F4F7",
    textTransform: "none",
    marginTop: "2%",
    marginRight: "1%",
  },
  topButton: {
    width: "fit-content",
    height: "100%",
    padding: "10px 16px",
    borderRadius: "64px",
    //border: '1px solid #E9DDFD',
    gap: "8px",
    background: "#F2F4F7",
    textTransform: "none",
    //marginTop: '2%',
    //marginRight: '1%',
  },
  addButton: {
    width: "fit-content",
    minWidth: "135px",
    height: "100%",
    padding: "8px 14px",
    borderRadius: "64px",
    gap: "8px",
    background: "#F9F5FF",
    textTransform: "none",
    marginRight: "1%",
  },
  removeButton: {
    width: "fit-content",
    minWidth: "125px",
    height: "100%",
    padding: "8px 14px",
    borderRadius: "64px",
    gap: "8px",
    //background: '#B42318',
    textTransform: "none",
    marginRight: "1%",
  },
  loadingIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <Checkbox ref={ref} indeterminate={indeterminate} {...rest} />;
}

const FilterButton = ({ text, onClose }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "4px 10px",
        backgroundColor: "#F2F4F7",
        color: "#344054",
        borderRadius: "16px",
        gap: "4px",
        fontSize: "14px",
        fontWeight: 600,
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      <div>{text.charAt(0).toUpperCase() + text.slice(1)}</div>
      <CloseIcon
        style={{ cursor: "pointer" }}
        fontSize="small"
        onClick={onClose}
      />
    </div>
  );
};

function SwingLibraryComponent() {
  const [isLoading, setIsLoading] = useState(true);

  const [role, setRole] = useState("");
  const [permission, setPermission] = useState("");
  const [user, setUser] = useState(null);

  const [analysisAggregates, setAnalysisAggregates] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [totalSwings, setTotalSwings] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [videos, setVideos] = useState([]);
  const [indicators, setIndicators] = useState([]);

  const [filterOptionsUsers, setFilterOptionsUsers] = useState([]);
  const [filterValueUsers, setFilterValueUsers] = useState([]);

  const [filterValueDate, setFilterValueDate] = useState(null);

  const [filterValueClub, setFilterValueClub] = useState([]);

  const [filterValueCameraAngle, setFilterValueCameraAngle] = useState([]);

  const [filterValueFilters, setFilterValueFilters] = useState([]);

  const [filterValueIndicators, setFilterValueIndicators] = useState([
    {
      id: "CHSTTRNTOP",
      name: "Chest Turn at TOP",
      positionOrPhase: "TOP",
      unit: "Degrees",
    },
    {
      id: "XFCTRMAXNRTOP",
      name: "X-Factor Max Near TOP",
      positionOrPhase: "Backswing",
      unit: "Degrees",
    },
    {
      id: "CHSTSBNDFTHRUMAX",
      name: "Chest Side Bend FollowThru Max",
      positionOrPhase: "FollowThru",
      unit: "Degrees",
    },
  ]);

  const [isIndicatorFilterOpen, setIsIndicatorFilterOpen] = useState(false);

  const [filterValueAnalyzed, setFilterValueAnalyzed] = useState([]);

  const [filterValueDominantHand, setFilterValueDominantHand] = useState([]);

  const [filterOptionsTags, setFilterOptionsTags] = useState([]);
  const [filterValueTags, setFilterValueTags] = useState([]);

  const [sorting, setSorting] = useState({
    sort: "videoCreated",
    order: "desc",
  });

  const fxdCols = [
    {
      id: "select",
      size: 50,
      header: ({ table }) => (
        <div style={{ padding: "0", margin: "0" }}>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div style={{ padding: "0", margin: "0" }}>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: "videoPath",
      accessorKey: "videoPath",
      maxSize: 150,
      minSize: 150,
      header: "Date",
      cell: ({ row }) =>
        row?.original?.videoPath?.includes(".com") ? (
          <PictureInPictureVideo
            videoPath={row.original.videoPath}
            videoCreated={row.original.videoCreated}
          />
        ) : (
          <b>{row?.original?.videoPath}</b>
        ),
    },
    {
      id: "club",
      accessorKey: "userData.golfClub",
      header: "Club",
      maxSize: 100,
      minSize: 100,
    },
    {
      id: "golfer",
      accessorKey: "userData.fullName",
      header: "Golfer",
      maxSize: 110,
      minSize: 110,
    },
    {
      id: "tags",
      accessorKey: "tags",
      header: "Tags",
      maxSize: 175,
      minSize: 175,
      cell: ({ row }) => (
        <TableRowTag
          values={row?.original?.tags ? row?.original?.tags : []}
          options={filterOptionsTags}
          videoId={row?.original?.id}
          onTagAdd={handleAddTag}
          onAddTagToVideo={handleAddTagToVideo}
        />
      ),
    },
  ];

  const [tableColumns, setTableColumns] = useState(fxdCols);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [isTourAveragesActive, setIsTourAveragesActive] = useState(false);
  const [indicatorStdAvg, setIndicatorStdAvg] = useState(null);

  useEffect(() => {
    const data = Object.keys(rowSelection).map((el) => {
      return videos[el];
    });

    setSelectedTableData(data);
  }, [rowSelection]);

  const loadUser = async () => {
    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/users/${uid}`,
      {
        method: "GET",
      }
    );

    setUser(response.data);
  };

  const loadStudents = async () => {
    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v3/loadStudentsForCoach`,
      {
        method: "POST",
        data: {
          limit: 20,
          offset: 0,
          sort: "userCreated",
          order: "desc",
          filter: { firstNameOrLastNameOrEmail: "" },
          coachId: uid,
          isPendingInviteNeeded: false,
          subscriptionLimit: 20,
        },
      }
    );

    const result = response.data.result.map((el) => {
      return {
        name: el?.user.firstName + " " + el?.user.lastName,
        email: el?.user.email,
        value: el?.user.id,
      };
    });
    setFilterOptionsUsers(result);
  };

  const loadTags = async () => {
    const data = await axiosWithToken(
      functionBaseUrl + "/api/swing-library/settings",
      {
        method: "POST",
        data: { userId: firebase.auth().currentUser.uid },
      }
    );

    if (data.data.tags) {
      setFilterOptionsTags(data.data.tags);
    }

    if (data.data.savedIndicators.length) {
      setFilterValueIndicators(data.data.savedIndicators);
    }

    if (data.data.tourAveragesToggle) {
      setIsTourAveragesActive(data.data.tourAveragesToggle);
    }
  };

  const loadUserData = async () => {
    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/users/${uid}`,
      {
        method: "GET",
      }
    );

    setRole(response.data?.role || "");
    setPermission(response.data?.permission || "");
    setUser(response.data);
  };

  const loadData = async (
    page,
    size,
    sorting,
    filterValueUsers,
    filterValueDate,
    filterValueClub,
    filterValueCameraAngle,
    filterValueAnalyzed,
    filterValueDominantHand,
    subscriptionLevel
  ) => {
    setIsLoading(true);

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;

    const requestBody = {
      limit: size,
      offset: page * size,
      sort: sorting.sort,
      order: sorting.order,
      userId: [uid],
    };

    if (filterValueUsers.length) {
      requestBody.userId = filterValueUsers;
    }

    if (filterValueDate) {
      if (filterValueDate?.startDate) {
        const startDate = new Date(filterValueDate.startDate);
        startDate.setHours(0, 0, 0, 0);
        requestBody.dateRangeStart = startDate.valueOf();
      }

      if (filterValueDate?.endDate) {
        const endDate = new Date(filterValueDate.endDate);
        endDate.setHours(23, 59, 59, 999);
        requestBody.dateRangeEnd = endDate.valueOf();
      }
    }

    if (filterValueClub.length) {
      requestBody.club = filterValueClub;
    }

    if (filterValueCameraAngle.length) {
      requestBody.cameraAngle = filterValueCameraAngle[0];
    }

    if (filterValueAnalyzed.length) {
      requestBody.analyzed = filterValueAnalyzed[0];
    }

    if (filterValueDominantHand.length) {
      requestBody.domHand = filterValueDominantHand[0];
    }

    if (filterValueTags.length) {
      requestBody.tags = filterValueTags;
    }

    //const response = await axiosWithToken('http://localhost:5000/sportsbox-3dgolf/us-central1/api/v1/swingList',
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v1/swingList`,
      {
        method: "POST",
        data: requestBody,
      }
    );

    const subTypeToLevelMap = (type) => {
      if (type === "Admin") {
        return type;
      } else if (type.includes("free")) {
        return "Free";
      } else if (type.includes("student_lite")) {
        return "3D Practice";
      } else {
        return "Pro-Lite";
      }
    };

    const indicatorsResponse = await axiosWithToken(
      `${functionBaseUrl}/api/utility/action-sdk-indicators?subscriptionLevel=${subTypeToLevelMap(
        subscriptionLevel
      )}`,
      {
        method: "GET",
      }
    );

    setVideos(response?.data?.videos);
    setAnalysisAggregates(response?.data?.analysisAggregates);
    setTotalSwings(response?.data?.totalSwings);
    setIndicatorStdAvg(response?.data?.tourAverageRow);

    setIndicators(
      indicatorsResponse?.data?.flatMap((category) =>
        category.items.map((item) => item.id)
      )
    );
    setIsLoading(false);
  };

  useEffect(() => {
    loadStudents();
    loadTags();
    loadUserData();
  }, []);

  useEffect(() => {
    if (user) {
      loadData(
        currentPage,
        pageSize,
        sorting,
        filterValueUsers,
        filterValueDate,
        filterValueClub,
        filterValueCameraAngle,
        filterValueAnalyzed,
        filterValueDominantHand,
        filterValueTags,
        user.role === "admin" ? "Admin" : user.subscriptionType
      );
    }
  }, [
    currentPage,
    pageSize,
    filterValueCameraAngle,
    filterValueClub,
    filterValueDate,
    filterValueUsers,
    filterValueAnalyzed,
    filterValueDominantHand,
    sorting,
    filterValueTags,
    user,
  ]);

  useEffect(() => {
    if (filterValueFilters.length) {
      if (
        !filterValueFilters.includes("analyzed") &&
        filterValueAnalyzed.length
      )
        setFilterValueAnalyzed([]);

      if (
        !filterValueFilters.includes("dominantHand") &&
        filterValueDominantHand.length
      )
        setFilterValueDominantHand([]);
    }

    if (currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [filterValueFilters]);

  const convertUnit = (unit, value) => {
    switch (unit.toLowerCase()) {
      case "degrees":
      case "degrees/second":
        return value; // Assuming value is already in degrees or degrees/sec
      case "inches":
        return value / 25.4; // Convert mm to inches (1 inch = 25.4 mm)
      case "mph":
        return value * 0.002237; // Convert mm/s to mph (1 mm/s = 0.002237 mph)
      case "m/s/s":
        return value / 1000; // Convert mm/s^2 to m/s^2
      case "g":
        return value / 9806.65; // Convert mm/s^2 to g (1 g ≈ 9806.65 mm/s^2)
      case "seconds":
        return value;
      case "ms":
        return value * 1000;
      case "percent":
        return value * 100; // Convert ratio to percentage
      case "ratio":
        return value; // Assuming ratio is unitless
      default:
        return value; // Return the original value if no conversion is needed
    }
  };

  const renderUnitType = (unit, value) => {
    if (value === "n/a") return value;
    const parsedUnit = unit ? unit.toLowerCase() : "";
    const parsedValue = value ? +value : 0;

    // Convert the value based on the unit
    const convertedValue = convertUnit(parsedUnit, parsedValue);

    switch (parsedUnit) {
      case "degrees":
        return `${convertedValue.toFixed()}°`;
      case "inches":
        return `${convertedValue.toFixed(1)}"`;
      case "mm":
        return `${convertedValue.toFixed()} mm`;
      case "seconds":
        return `${convertedValue.toFixed(2)} sec`;
      case "ratio":
        return `${convertedValue.toFixed(2)} ratio`;
      case "ms":
        return `${convertedValue.toFixed()} ms`;
      case "percent":
        return `${convertedValue.toFixed()}%`;
      case "mph":
        return `${convertedValue.toFixed(1)} mph`;
      case "m/s/s":
        return `${convertedValue.toFixed(2)} m/s²`;
      case "g":
        return `${convertedValue.toFixed(1)} g`;
      case "degrees/second":
        return `${convertedValue.toFixed()} d/s`;
      case "order":
        return `${convertedValue.toFixed()}`;
      default:
        return convertedValue.toFixed();
    }
  };

  useEffect(() => {
    if (filterValueTags) {
      setTableColumns((prev) => {
        const copy = [...prev];
        copy[4] = {
          id: "tags",
          accessorKey: "tags",
          header: "Tags",
          maxSize: 1000,
          minSize: 200,
          cell: ({ row }) => (
            <TableRowTag
              values={["tag1", "tag2"]}
              options={filterOptionsTags}
              videoId={row?.original?.id}
            />
          ),
        };
        return copy;
      });
    }

    if (filterValueIndicators.length) {
      setTableColumns([
        ...fxdCols,
        ...filterValueIndicators.map((el) => {
          return {
            accessorKey: "analysis.indicators." + el.id,
            header: el.name,
            id: el.id,
            size: 100,
            cell: ({ row }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                  gap: "10px",
                }}
              >
                {row?.original?.analysis?.scoreIndicators &&
                el.id in row?.original?.analysis?.scoreIndicators ? (
                  <span
                    style={{
                      color:
                        row?.original?.analysis?.scoreIndicators[el.id].color,
                    }}
                  >
                    {renderUnitType(
                      el.unit,
                      row?.original?.analysis?.scoreIndicators[el.id].point
                    )}
                  </span>
                ) : (
                  <span> {renderUnitType(el.unit, "n/a")}</span>
                )}

                <span style={{ width: "fit-content" }}>
                  {row?.original?.analysis?.indicators &&
                  el.id in row?.original?.analysis?.indicators
                    ? renderUnitType(
                        el.unit,
                        row?.original?.analysis?.indicators[el.id]
                      )
                    : renderUnitType(el.unit, "n/a")}
                </span>
                {/* <span
                  style={{
                    border: "1px solid #400AA5",
                    borderRadius: "18px",
                    padding: "2px 5px",
                    width: "fit-content",
                  }}
                >
                  {row?.original?.analysis?.indicators &&
                  el.id in row?.original?.analysis?.indicators
                    ? row?.original?.analysis?.indicators[el.id].toFixed(2)
                    : 0}
                </span> */}
              </div>
            ),
          };
        }),
      ]);
    }
  }, [filterValueIndicators, filterOptionsTags]);

  const downloadVideos = (videos) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Downloading videos...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    const names = Object.entries(videos).map(([id, v]) => {
      return (
        v?.videoOrigName +
        (v?.videoType !== "video/*" ? "." + v?.videoType : "")
      );
    });
    const promises = Object.entries(videos).map(([id, obj]) => {
      //Get video from url and return as a blob
      return axios({
        url: obj?.videoPath,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("Failed to download " + obj?.videoOrigName);
          return null;
        });
    });
    //If only one item is selected, do not zip the file and just download it
    if (promises.length === 1) {
      promises[0].then((response) => {
        if (response) {
          downloadFile(names[0], response.data);
          Swal.close();
        }
      });
    } else {
      //Zip all video files and download them
      Promise.all(promises).then((responses) => {
        const zip = new JSZip();
        responses.forEach((res, index) => {
          if (res) {
            zip.file(names[index], res.data);
          }
        });
        zip.generateAsync({ type: "blob" }).then((blob) => {
          downloadFile("videos.zip", blob);
          Swal.close();
        });
      });
    }
  };

  //Downloads a blob with filename name
  const downloadFile = (name, blob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  function downloadJSONAsCSV(jsonData, filename) {
    try {
      let aggregatesData = {};
      for (const key of indicators) {
        const values = jsonData
          .map((row) => row?.analysis?.indicators?.[key])
          .filter((val) => val !== undefined && val !== null);

        if (values.length > 0) {
          const sum = values.reduce((acc, val) => acc + val, 0);
          const avg = sum / values.length;
          const std = Math.sqrt(
            values.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) /
              values.length
          );
          aggregatesData[key] = { avg, std };
        } else {
          aggregatesData[key] = { avg: 0, std: 0 };
        }
      }

      const statsRows = [
        {
          videoPath: "Averages",
          videoCreated: "",
          userData: { golfClub: "", fullName: "" },
          tags: "",
          analysis: {
            indicators:
              aggregatesData &&
              Object.fromEntries(
                Object.entries(aggregatesData).map(([key, value]) => [
                  key,
                  value.avg,
                ])
              ),
          },
        },
        {
          videoPath: "Std. Deviation",
          videoCreated: "",
          userData: { golfClub: "", fullName: "" },
          tags: "",
          analysis: {
            indicators:
              aggregatesData &&
              Object.fromEntries(
                Object.entries(aggregatesData).map(([key, value]) => [
                  key,
                  value.std,
                ])
              ),
          },
        },
      ];
      jsonData = [...statsRows, ...jsonData];
      const { csvData, columns } = flattenData(jsonData);

      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);

      // Create a link element to simulate a click and trigger the download
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the link element and revoke the URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during CSV download:", error);
    }
  }

  function flattenData(jsonData) {
    // Get unique column names, including cleaned up indicator keys
    const columns = [
      "Video",
      "Date",
      "Golf Club",
      "Name",
      "Tags",
      ...getIndicatorKeys(jsonData).map((key) =>
        key.replace("analysis.indicators.", "")
      ),
    ];

    let csv = columns.join(",") + "\n";

    jsonData.forEach((row) => {
      const data = columns
        .map((col) => {
          if (col === "Video") return row?.videoPath || "";
          if (col === "Date")
            return row?.videoCreated
              ? moment(parseInt(row?.videoCreated)).format("MM/DD/YY HH:MM")
              : "";
          if (col === "Golf Club") return row?.userData?.golfClub || "";
          if (col === "Name") return row?.userData?.fullName || "";
          if (col === "Tags") {
            const tags = Array.isArray(row?.tags) ? row.tags.join(", ") : "";
            return `"${tags}"`;
          }
          // Match the cleaned column names with the full indicator keys
          return (
            row?.analysis?.indicators?.[
              col.replace("analysis.indicators.", "")
            ] || ""
          );
        })
        .join(",");
      csv += data + "\n";
    });

    return { csvData: csv, columns };
  }

  function getIndicatorKeys(jsonData) {
    const indicatorKeys = new Set();
    jsonData.forEach((row) => {
      if (row.analysis?.indicators) {
        Object.keys(row.analysis.indicators).forEach((key) => {
          indicatorKeys.add(key); // Use only the key, without prefix
        });
      }
    });
    return Array.from(indicatorKeys);
  }

  const exportSelectedSwings = (swings) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Exporting data...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    downloadJSONAsCSV(swings, "swings");
    Swal.close();
  };

  const exportAllSwings = async () => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Exporting data...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;

    const requestBody = {
      limit: 10000,
      offset: 0,
      sort: "videoCreated",
      order: "desc",
      userId: [uid],
      loadAll: true,
    };

    if (filterValueUsers.length) {
      requestBody.userId = filterValueUsers;
    }

    if (filterValueDate) {
      if (filterValueDate?.startDate) {
        const startDate = new Date(filterValueDate.startDate);
        startDate.setHours(0, 0, 0, 0);
        requestBody.dateRangeStart = startDate.valueOf();
      }

      if (filterValueDate?.endDate) {
        const endDate = new Date(filterValueDate.endDate);
        endDate.setHours(23, 59, 59, 999);
        requestBody.dateRangeEnd = endDate.valueOf();
      }
    }

    if (filterValueClub.length) {
      requestBody.club = filterValueClub;
    }

    if (filterValueCameraAngle.length) {
      requestBody.cameraAngle = filterValueCameraAngle[0];
    }

    if (filterValueAnalyzed.length) {
      requestBody.analyzed = filterValueAnalyzed[0];
    }

    if (filterValueDominantHand.length) {
      requestBody.domHand = filterValueDominantHand[0];
    }

    if (filterValueTags.length) {
      requestBody.tags = filterValueTags;
    }

    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v1/swingList`,
      {
        method: "POST",
        data: requestBody,
      }
    );

    const responseData = response?.data?.filter(
      (el) => el?.videoPath !== "Averages" && el?.videoPath !== "Std. Deviation"
    );
    downloadJSONAsCSV(responseData, "swings");
    Swal.close();
  };

  const classes = useStyles();

  const handleUserFilterValueChange = (data) => {
    setFilterValueUsers(data);
  };

  const handleFilterValueChange = (filterName, value) => {
    const toggleMultipleOptions = (prev) => {
      if (prev.includes(value)) {
        return prev.filter((el) => el !== value);
      } else {
        return [...prev, value];
      }
    };

    const toggleSingleOption = (prev) => {
      if (prev[0] === value) {
        return [];
      }
      return [value];
    };

    switch (filterName) {
      case "golfer": {
        setFilterValueUsers(toggleMultipleOptions);
        break;
      }
      case "date": {
        setFilterValueDate(value.date);
        break;
      }
      case "club": {
        setFilterValueClub(toggleMultipleOptions);
        break;
      }
      case "cameraAngle": {
        setFilterValueCameraAngle(toggleSingleOption);
        break;
      }
      case "filters": {
        setFilterValueFilters(toggleMultipleOptions);
        break;
      }
      case "analyzed": {
        setFilterValueAnalyzed(toggleSingleOption);
        break;
      }
      case "tags": {
        setFilterValueTags(toggleMultipleOptions);
        break;
      }
      case "dominantHand": {
        setFilterValueDominantHand(toggleSingleOption);
        break;
      }
    }

    if (currentPage !== 0) {
      setCurrentPage(0);
    }
  };

  const handleOrderChange = (sort, order) => {
    switch (sort) {
      case "userData.golfClub":
        setSorting({ sort: "userData.golfClub", order });
        break;
      case "userData.fullName":
        setSorting({ sort: "userData.fullName", order });
        break;
      case "videoPath":
        setSorting({ sort: "videoCreated", order });
        break;
    }
  };

  const statsRows = [
    {
      videoPath: "Averages",
      videoCreated: "",
      userData: { golfClub: "", fullName: "" },
      analysis: {
        indicators:
          analysisAggregates &&
          Object.fromEntries(
            Object.entries(analysisAggregates).map(([key, value]) => [
              key,
              value.avg,
            ])
          ),
      },
    },
    {
      videoPath: "Std. Deviation",
      videoCreated: "",
      userData: { golfClub: "", fullName: "" },
      analysis: {
        indicators:
          analysisAggregates &&
          Object.fromEntries(
            Object.entries(analysisAggregates).map(([key, value]) => [
              key,
              value.std,
            ])
          ),
      },
    },
  ];

  const handleAddTag = async (tag) => {
    await axiosWithToken(functionBaseUrl + "/api/swing-library/settings/tags", {
      method: "POST",
      data: {
        userId: firebase.auth().currentUser.uid,
        tag,
        type: "add",
      },
    });

    const data = await axiosWithToken(
      functionBaseUrl + "/api/swing-library/settings",
      {
        method: "POST",
        data: { userId: firebase.auth().currentUser.uid },
      }
    );

    setFilterOptionsTags(data.data.tags);
  };

  const handleAddTagToVideo = useCallback(async () => {
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    await delay(1000);
    await loadData(
      currentPage,
      pageSize,
      sorting,
      filterValueUsers,
      filterValueDate,
      filterValueClub,
      filterValueCameraAngle,
      filterValueAnalyzed,
      filterValueDominantHand,
      filterValueTags
    );
  }, [
    currentPage,
    pageSize,
    filterValueCameraAngle,
    filterValueClub,
    filterValueDate,
    filterValueUsers,
    filterValueAnalyzed,
    filterValueDominantHand,
    sorting,
    filterValueTags,
  ]);

  const callToSwingLibraryUpdate = async (data) => {
    await axiosWithToken(
      functionBaseUrl + "/api/swing-library/settings-update",
      {
        method: "POST",
        data: {
          userId: firebase.auth().currentUser.uid,
          data: { savedIndicators: data },
        },
      }
    );
  };

  const toggleTourAverages = useCallback(async () => {
    await axiosWithToken(
      functionBaseUrl + "/api/swing-library/settings-update",
      {
        method: "POST",
        data: {
          userId: firebase.auth().currentUser.uid,
          data: { tourAveragesToggle: !isTourAveragesActive },
        },
      }
    );
    setIsTourAveragesActive(!isTourAveragesActive);
  }, [isTourAveragesActive]);

  const handleIndicatorSelectSubmit = useCallback(async (data) => {
    setFilterValueIndicators(data);
    await callToSwingLibraryUpdate(data);
  }, []);

  const handleSingleIndicatorRemove = useCallback(
    async (item) => {
      const newData = filterValueIndicators.filter((fl) => fl.id !== item.id);
      setFilterValueIndicators((prev) =>
        prev.filter((fl) => fl.id !== item.id)
      );
      await callToSwingLibraryUpdate(newData);
    },
    [filterValueIndicators]
  );

  const handleIndicatorClear = useCallback(async () => {
    await callToSwingLibraryUpdate([]);
    setFilterValueIndicators([]);
    setTableColumns(fxdCols);
  }, []);

  return (
    <div style={{ padding: "0px 20px 0px", width: "100%" }}>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.heading}>
          Swing List
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            flexWrap: "wrap",
            width: "25vw",
          }}
        >
          <Button
            className={classes.topButton}
            disabled={selectedTableData.length === 0}
            onClick={() => downloadVideos(selectedTableData)}
          >
            <GetAppIcon />
          </Button>
          {(role === "admin" || permission === "internal") && (
            <>
              <Button
                className={classes.topButton}
                onClick={() => exportAllSwings()}
              >
                <Typography className={classes.cardText}>Export All</Typography>
              </Button>
              <Button
                className={classes.topButton}
                style={{ background: "#CBB1FB" }}
                disabled={selectedTableData.length === 0}
                onClick={() => exportSelectedSwings(selectedTableData)}
              >
                <Typography className={classes.cardText}>Export</Typography>
              </Button>
            </>
          )}
        </div>
      </div>

      <Divider className={classes.divider} />

      <IndicatorSelectList
        isOpen={isIndicatorFilterOpen}
        onClose={() => setIsIndicatorFilterOpen(false)}
        onSave={(data) => handleIndicatorSelectSubmit(data)}
        values={filterValueIndicators}
        user={user}
      />

      <div className={classes.container}>
        <Card className={classes.card}>
          <div
            className={classes.container}
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <SelectV3WithServerSearch
                values={filterValueUsers}
                name={"golfer"}
                onChange={handleFilterValueChange}
                emptyValue={"Golfer"}
                optionsLabel={"Golfers"}
                options={filterOptionsUsers}
                searchRequest={async (searchValue) => {
                  const response = await axiosWithToken(
                    functionBaseUrl + "/api/elastic/searchByNameOrEmail",
                    {
                      method: "POST",
                      data: {
                        text: searchValue,
                        coachId:
                          role === "admin" || permission === "internal"
                            ? ""
                            : firebase.auth().currentUser?.uid,
                      },
                    }
                  );

                  return response.data.map((el) => {
                    return {
                      name: el.firstName + " " + el.lastName,
                      email: el.email,
                      value: el.id,
                    };
                  });
                }}
              />

              <SelectV3WithDatePicker
                values={filterValueDate}
                name={"date"}
                onChange={handleFilterValueChange}
                emptyValue={"Date"}
                onClear={() => setFilterValueDate(null)}
              />

              <ClubSelect
                values={filterValueClub}
                name={"club"}
                onChange={handleFilterValueChange}
                emptyValue={"Club"}
                options={[
                  {
                    label: "Woods & Hybrids",
                    options: [
                      { value: "D", text: "D" },
                      { value: "3w", text: "3w" },
                      { value: "5w", text: "5w" },
                      { value: "7w", text: "7w" },
                      { value: "9w", text: "9w" },
                      { value: "2h", text: "2h" },
                      { value: "3h", text: "3h" },
                      { value: "4h", text: "4h" },
                      { value: "5h", text: "5h" },
                      { value: "6h", text: "6h" },
                    ],
                  },
                  {
                    label: "Irons & Wedges",
                    options: [
                      { value: "3i", text: "3i" },
                      { value: "4i", text: "4i" },
                      { value: "5i", text: "5i" },
                      { value: "6i", text: "6i" },
                      { value: "7i", text: "7i" },
                      { value: "8i", text: "8i" },
                      { value: "9i", text: "9i" },
                      { value: "PW", text: "PW" },
                      { value: "GW", text: "GW" },
                      { value: "SW", text: "SW" },
                      { value: "LW", text: "LW" },
                    ],
                  },
                ]}
              />

              <SelectV3
                values={filterValueCameraAngle}
                name={"cameraAngle"}
                onChange={handleFilterValueChange}
                emptyValue={"Camera Angle"}
                optionsLabel={"Camera Angles"}
                options={[
                  { value: "FON", text: "Face On" },
                  { value: "DTL", text: "Down the Line" },
                ]}
              />

              <SelectV3
                values={filterValueAnalyzed}
                name={"analyzed"}
                onChange={handleFilterValueChange}
                emptyValue={"Status"}
                optionsLabel={"Status"}
                options={[
                  { value: true, text: "Analyzed" },
                  { value: false, text: "Not Analyzed" },
                ]}
              />

              <TagsSelect
                values={filterValueTags}
                name={"tags"}
                onChange={handleFilterValueChange}
                emptyValue={"Tags"}
                optionsLabel={"Tags"}
                options={filterOptionsTags}
                onClear={() => setFilterValueTags([])}
                allowLocalSearch
              />

              <SelectV3
                values={filterValueFilters}
                name={"filters"}
                onChange={handleFilterValueChange}
                emptyValue={"Filters"}
                optionsLabel={"Filters"}
                showSelectedNumberOnValue
                options={[
                  /*{ value: "analyzed", text: "Status" },
                  { value: "file", text: "File" },
                  { value: "name", text: "Name" },
                  { value: "type", text: "Type" },
                  { value: "length", text: "Length" },
                  { value: "size", text: "Size" },
                  { value: "internalLabel", text: "Internal Label" },
                  { value: "twoDSslTag", text: "2D SSL Tag" },*/
                  { value: "dominantHand", text: "Dominant Hand" },
                ]}
              />

              {filterValueFilters.includes("dominantHand") && (
                <SelectV3
                  values={filterValueDominantHand}
                  name={"dominantHand"}
                  onChange={handleFilterValueChange}
                  emptyValue={"Dominant Hand"}
                  optionsLabel={"Dominant Hand"}
                  options={[
                    { value: "Left", text: "Left Hand" },
                    { value: "Right", text: "Right Hand" },
                  ]}
                />
              )}

              {filterValueFilters.length ? (
                <div
                  style={{
                    borderLeft: "1px solid #D0D5DD",
                    paddingLeft: "10px",
                  }}
                >
                  {filterValueFilters
                    .filter((el) => {
                      return el !== "analyzed" && el !== "dominantHand";
                    })
                    .map((el) => {
                      return (
                        <FilterButton
                          text={el}
                          onClose={() =>
                            setFilterValueFilters((prev) =>
                              prev.filter((fl) => fl !== el)
                            )
                          }
                        />
                      );
                    })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <Divider className={classes.divider} />

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexWrap: isDesktop ? "" : "wrap",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Tour Averages
                </span>
                <IOSSwitch
                  checked={isTourAveragesActive}
                  onClick={toggleTourAverages}
                />
              </div>
              <div
                style={{ borderLeft: "#D2D6DB 1px solid", height: "40px" }}
              ></div>

              <Button
                onClick={() => setIsIndicatorFilterOpen(true)}
                className={classes.addButton}
              >
                <Typography className={classes.cardText}>
                  <div
                    style={{ fontSize: "14px" }}
                    className={classes.container}
                  >
                    Add data &nbsp;&nbsp;
                    <img src="/images/icons/plus.png" />
                  </div>
                </Typography>
              </Button>
              <Button
                onClick={handleIndicatorClear}
                className={classes.removeButton}
              >
                <Typography
                  className={classes.cardText}
                  style={{ color: "#B42318", fontSize: "14px" }}
                >
                  Remove all &nbsp;&nbsp;
                </Typography>
              </Button>
              <div
                style={{ borderLeft: "#D2D6DB 1px solid", height: "40px" }}
              ></div>
            </div>

            {filterValueIndicators.length ? (
              <div style={{ marginLeft: "10px" }}>
                <HorizontalScrollWrapper>
                  {filterValueIndicators.map((el) => {
                    return (
                      <FilterButton
                        key={el.id}
                        text={el.name}
                        onClose={() => handleSingleIndicatorRemove(el)}
                      />
                    );
                  })}
                </HorizontalScrollWrapper>
              </div>
            ) : (
              <></>
            )}
          </div>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.vertDivider}
          />
        </Card>
      </div>
      <Divider className={classes.divider} />
      {/*<Grid item xs={12}>*/}
      {isLoading ? (
        <div className={classes.loadingIcon}>
          <CircularProgress style={{ display: isLoading ? "block" : "none" }} />
        </div>
      ) : (
        <>
          <SwingTable2
            analysisAggregates={analysisAggregates}
            data={videos}
            currentPage={currentPage}
            pageNumber={Math.ceil(totalSwings / pageSize)}
            onPageChange={(page) => setCurrentPage(page)}
            sort={sorting}
            onSort={handleOrderChange}
            columns={tableColumns}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            selectedTableData={selectedTableData}
            rowsPerPage={pageSize}
            onRowsPerPageChange={setPageSize}
            filterValueIndicators={filterValueIndicators}
            isTourAveragesActive={isTourAveragesActive}
            indicatorStdAvg={indicatorStdAvg}
          />
          {/* <Swings
            videos={videos}
            analysisAggregates={analysisAggregates}
            onSelectionChange={(rows) => setSelectedVideos(rows)}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            totalSwings={totalSwings}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            columns={tableColumns}
          /> */}
        </>
      )}
      {/*</Grid>*/}
    </div>
  );
}

export const SwingLibrary = SwingLibraryComponent;
